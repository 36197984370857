import { KeycloakConfig } from 'keycloak-js';
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.

let keycloakConfig: KeycloakConfig = {
  url: 'https://auth.ecotx.dev',
  realm: 'eco-realm',
  clientId: 'ecotx-web',
};

let powerBiConfig = {
  reportBaseURL: 'https://app.powerbi.com/reportEmbed',
  qnaBaseURL: 'https://app.powerbi.com/qnaEmbed',
  tileBaseURL: 'https://app.powerbi.com/embed',
  groupID: '3aca5f3d-d7af-4311-a3c7-89f8716479bc',
  reportID: '619c668f-9be0-4589-ae6d-3f456847eeb2',
};

export const environment = {
  legacyLayout: false,
  production: true,
  appVersion: 'ecotx-web.v1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: false,
  apiUrl: 'https://platform-service.ecotx.dev/api',
  apiGraphqLUrl: 'https://platform-service.ecotx.dev/graphql',
  userProfileApiUrl: 'https://user.ecotx.dev',
  mapServicesUrl: 'https://map-services.ecotx.dev',
  paymentsApiUrl: 'https://payments.ecotx.dev',
  keycloak: keycloakConfig,
  powerBi: powerBiConfig,
  eMapUrl: 'https://map.ecotx.dev',
  workspaceUrl: 'https://workspace.ecotx.dev',
  ecotxBackofficeUrl: 'https://backoffice.ecotx.dev',
  ecotxNotificationsWSS: 'wss://notifications.ecotx.dev/websocket',
  tocPdfUrl: 'https://user.ecotx.dev/DATLAZ-TOS.pdf',
  eCnpjUrl: 'https://e-cnpj.ecotx.dev/rede',
  mediaFileUrl: 'https://geoserver.ecotx.dev/storage/media',
  mediaUrl: 'https://media-service.ecotx.dev/media',
  layerServiceDataUrl: 'https://layer-service-data.ecotx.dev',
  mapboxGlToken:
    'pk.eyJ1IjoiZWNvdHgiLCJhIjoiY2ttdmVhdHgwMDQ4bzJva3Zoc291MnByaCJ9.SloN4lYumupiPOXz8eshuA',
  hotjar: {
    enabled: false,
    siteId: 3340857,
    hotjarVersion: 6,
    debug: true,
    identify: false
  },
  leadsConfig: {
    pricePerLeadInReal: 1.50,
    layerId: "457abd44-725e-4379-8eef-44b080a81c25",
    geometryPropertiesId: {
      city: "929b7198-8cf9-4304-a3f1-77fa694312c5",
      neighborhood: "7f40bfd2-af87-4435-a7c2-c0cf5b595c25",
      tariffGroup: "f649da46-e64d-4458-af58-cfb0fe00466e",
      consuptionClass:  "430d2ae0-b84f-40db-8c12-b51fbd7968b1",
      cegGd: "8984a14c-b25b-4bfc-8c49-57f757c07a83",
      distributor: "0d9cdac8-8851-4639-b8fa-9e71408c1f26",
      consuption: "5baf63d0-c6cd-4ead-b81c-610eff5f3af3",
      demand: "d50cd7db-2cf4-4fde-80cd-fe05c873e645",
      freeMarket: "004856b4-d2f4-4f92-abf3-8c2d2a70b4b7",
    }
  },
  analytics: {
    identify: false,
    trackLogout: false
  },
  publicMapId: ""
};