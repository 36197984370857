import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-disconnect-modal",
  templateUrl: "./disconnect-modal.component.html",
  styleUrls: ["./disconnect-modal.component.scss"],
})
export class DisconnectModalComponent implements OnInit {
  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}
}
