<!-- Modal content-->
<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Logout por Múltiplas Sessões</h4>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <p>
          Prezado usuário,<br>
          <br>
          Gostaríamos de informar que sua sessão foi encerrada devido à detecção de múltiplas sessões ativas em sua
          conta, excedendo o número máximo de conexões simultâneas permitidas.
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modal.dismiss()">
      {{ 'KEYWORDS.CLOSE' | translate }}
    </button>
  </div>
</div>
